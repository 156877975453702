import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { DYNAMIC_BOX_SHADOW } from '@core-ui/table';
import { SxProps, Theme } from '@mui/material/styles';
import { articleItemWidth, articlesContainerWidth, monthItemWidth, totalItemWidth } from '@/pages/finances/consts';

const border = `1px solid ${COLOURS.BORDER.MAIN}`;

export default (): Record<string, SxProps<Theme>> => ({
  costArticleContainer: {
    flex: `0 0 ${articlesContainerWidth}px`,
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
    color: COLOURS.HUE_COCONUT.HUE_12,
  },

  costArticleColor: {
    border,
    borderRight: 'none',
    width: 6,
    height: HEIGHT_SIZE.headerHeight,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
  },

  costArticleName: {
    borderTop: border,
    borderBottom: border,
    borderRight: border,
    width: articleItemWidth,
    height: HEIGHT_SIZE.headerHeight,

    ...DYNAMIC_BOX_SHADOW.dynamicRight,
  },

  monthName: {
    borderTop: border,
    borderBottom: border,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    width: monthItemWidth,
    flex: `0 0 ${monthItemWidth}px`,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
    color: COLOURS.HUE_COCONUT.HUE_12,
  },

  totalNameHeader: {
    border,
    height: HEIGHT_SIZE.headerHeight,
    flex: `1 0 ${totalItemWidth}px`,
    position: 'sticky',
    zIndex: 1,
    right: 0,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
    color: COLOURS.HUE_COCONUT.HUE_12,

    ...DYNAMIC_BOX_SHADOW.dynamicLeft,
  },

  totalContainer: {
    flex: `0 0 ${articlesContainerWidth}px`,
    position: 'sticky',
    zIndex: 1,
    left: 0,
    height: HEIGHT_SIZE.headerHeight,
    backgroundColor: COLOURS.BACKGROUND.MAIN,
    color: COLOURS.HUE_COCONUT.HUE_12,
    borderBottom: `3px solid ${COLOURS.BORDER.MAIN}`,

    ...DYNAMIC_BOX_SHADOW.dynamicRight,
  },

  totalColor: {
    border,
    borderRight: 'none',
    width: 6,
    height: HEIGHT_SIZE.headerHeight,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_12,
  },

  totalName: {
    border,
    borderRight: border,
    borderLeft: '0 solid',
    width: articleItemWidth,
    height: HEIGHT_SIZE.headerHeight,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_12,
    color: COLOURS.HUE_COCONUT.HUE_195,
  },

  monthCell: {
    borderBottom: border,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    width: monthItemWidth,
    flex: `0 0 ${monthItemWidth}px`,
  },

  totalCell: {
    borderTop: border,
    borderBottom: `3px solid ${COLOURS.BORDER.MAIN}`,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    flex: `1 0 ${totalItemWidth}px`,
    position: 'sticky',
    zIndex: 1,
    right: 0,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_12,

    ...DYNAMIC_BOX_SHADOW.dynamicLeft,
  },
});
